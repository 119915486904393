import React from "react"
import Footer from "../components/Footer"

export default function Error404() {
  return (
    <div id="page-wrapper">
      <div id="wrapper">
        <section className="panel banner right">
          <div className="content color4 span-5-5">
            <h1 className="major">Error 404<br />Not Found</h1>
            <p>Please return to the homepage: <a href="/">PhilStevenson.tech</a>.</p>
          </div>
        </section>
        <Footer/>
      </div>
    </div>
  );
}
